<template>
  <section class="welcome" :class="sectionClass">
    <div class="app-container pt-10">
      <div class="welcome__text pb-8">
        <h1 class="font-weight-light text-h4">Welcome to Audit Angel</h1>
        <h2 class="font-weight-medium text-h4">
          Clinical auditing and reporting platform
        </h2>
      </div>

      <v-row v-if="!statistics" class="welcome__sceleton pb-10">
        <v-col><v-skeleton-loader type="card" /></v-col>
        <v-col><v-skeleton-loader type="card" /></v-col>
        <v-col><v-skeleton-loader type="card" /></v-col>
        <v-col><v-skeleton-loader type="card" /></v-col>
      </v-row>

      <div
        v-if="statistics"
        class="welcome__items d-flex pb-10 justify-start flex-wrap align-center"
      >
        <welcome-item
          v-for="item in items"
          :key="item.text"
          :is-mobile-view="$vuetify.breakpoint.mobile"
          :amount="getStatisticsValue(item.dataKey)"
          :text="item.text"
          :icon="item.icon"
          :color="item.iconColor"
          :link-to="item.linkTo"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { getHomePageStatistics } from "../../services/statistics";
import WelcomeItem from "./WelcomeItem.vue";
import PrescriptionIcon from "../icons/PrescriptionIcon.vue";
import StethoscopeIcon from "../icons/StethoscopeIcon.vue";
import CommunicationIcon from "../icons/CommunicationIcon.vue";
import MedicalHistoryIcon from "../icons/MedicalHistoryIcon.vue";

export default {
  name: "WelcomeItemsContainer",

  components: {
    WelcomeItem,
  },

  data() {
    const itemsData = [
      {
        iconColor: "#FF9B63",
        icon: PrescriptionIcon,
        text: "Open audits",
        dataKey: "openBatches",
        linkTo: "/batches?opened=true",
      },
      {
        iconColor: "#FFD463",
        icon: CommunicationIcon,
        text: "Open Communication Cases",
        dataKey: "communicationCases",
        linkTo: "/doc-audit?forCommunication=true",
      },
      {
        iconColor: "#496FF4",
        icon: MedicalHistoryIcon,
        text: "Additional Audit Cases",
        dataKey: "additionalAudits",
        linkTo: "/special-audit?specialAudit=true",
      },
      {
        iconColor: "#01C6D0",
        icon: StethoscopeIcon,
        text: "New clinicians to review",
        dataKey: "newClinicians",
        linkTo: "/clinicians?newClinicians=true",
      },
    ];

    return {
      itemsData,
      statistics: null,
    };
  },

  computed: {
    sectionClass() {
      return { "mobile-view": this.$vuetify.breakpoint.mobile };
    },
    items() {
      return this.itemsData;
    },
  },

  async mounted() {
    await this.getMainStatistics();
  },

  methods: {
    getStatisticsValue(key) {
      if (!this.statistics) return 0;
      return this.statistics[key] || 0;
    },
    async getMainStatistics() {
      try {
        this.statistics = await getHomePageStatistics();
      } catch (e) {
        this.$notify({
          type: "error",
          title: "Fetch statistics error",
          text: e?.message || JSON.stringify(e),
        });
      }
    },
  },
};
</script>

<style lang="scss">
.welcome {
  background-color: #f0f0f0;

  &__sceleton {
    height: 270px;

    .v-skeleton-loader__image {
      height: 150px !important;
    }
  }

  &__items {
    gap: 24px;
  }

  &.mobile-view {
    .welcome {
      &__text {
        font-size: 24px;
        line-height: 30px;
      }

      &__items {
        gap: 8px;
      }
    }
  }
}
</style>
